//我的审核
<template>
  <Page>
    <el-tabs slot="tabs" v-model="params.status" @tab-click="handleClick">
      <el-tab-pane label="待处理" :value="0"></el-tab-pane>
      <el-tab-pane label="已处理" :value="1"></el-tab-pane>
    </el-tabs>
    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="申请单号" prop="orderCode">
          <el-input
            v-model="params.orderCode"
            clearable
            placeholder="请输入申请单号"
          />
        </el-form-item>
        <el-form-item label="业务类型" prop="type">
          <el-select v-model="params.type" clearable placeholder="请选择">
            <el-option
              v-for="item in $constant.applyType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="申请人" prop="applier">
          <SelectSearch
            ref="SelectSearch"
            searchKey="nickname"
            labelKey="nickname"
            :searchFn="listUsers"
            clearable
            @change="data => (params.applier = data.id)"
          />
        </el-form-item>
        <el-form-item
          label="申请单状态"
          v-if="params.status == 1"
          prop="auditStatus"
        >
          <el-select
            v-model="params.auditStatus"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in $constant.auditStatus2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="申请时间"
          v-if="params.status == 1"
          prop="selectTime"
        >
          <el-date-picker
            v-model="params.selectTime"
            type="daterange"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            align="right"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          />
        </el-form-item>
      </el-form>
    </template>
    <template
      slot="otherBtn"
      v-if="params.type && params.type != 5"
    >
      <el-button type="primary" @click="handleExport()">
        导出申请物品清单
      </el-button>
    </template>
    <div class="table-wrap">
      <el-table border :data="tableData" v-loading="loading">
        <el-table-column align="center" label="序号" type="index" width="60" />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="unique"
          label="申请单号"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="applyTypeStr"
          label="业务类型"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="applier.nickname"
          label="申请人"
        />
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="申请时间"
        >
          <template slot-scope="{ row }">
            {{ row.applyTime | date('YYYY-mm-dd HH:MM:SS') }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          label="审批时间"
          v-if="params.status == 1"
        >
          <template slot-scope="{ row }">
            {{ row.auditTime | date('YYYY-mm-dd HH:MM:SS') }}
          </template>
        </el-table-column>
        <el-table-column
          header-align="center"
          align="center"
          show-overflow-tooltip
          prop="auditStatusStr"
          label="我的操作"
          v-if="params.status == 1"
        />
		<el-table-column
		  header-align="center"
		  align="center"
		  show-overflow-tooltip
		  prop="flowableStatusStr"
		  label="申请单状态"
		  v-if="params.status == 1"
		/>
		<el-table-column
		  align="center"
		  show-overflow-tooltip
		  prop="remark"
		  label="备注"
		/>
        <el-table-column
          header-align="center"
          show-overflow-tooltip
          label="操作"
          width="70"
        >
          <template slot-scope="{ row }">
            <el-button
              size="medium"
              type="text"
              v-auth="'check'"
              @click="toDetail(row)"
            >
              {{ params.status == 1 ? '查看' : '审批' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Pagination
      :total="total"
      :page-size.sync="params.pageSize"
      :page.sync="params.pageNo"
      @change="getData"
    />
  </Page>
</template>

<script>
import watchParamsGetData from '../../mixins/watchParamsGetData'
import { listUsers } from '@/api/flowable.js'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      listUsers,
      params: {
        status: 0,
        orderCode: '',
        type: '',
        auditStatus: '',
        selectTime: ''
      }
    }
  },
  methods: {
    selfReset() {
      this.$refs.SelectSearch?.clear()
    },
    handleClick() {
      this.params.auditStatus = ''
      this.params.selectTime = undefined
      this.params.pageNo = 1
      this.$nextTick(() => {
        this.getData()
      })
    },
    async handleExport() {
	  let done = this.params.status == 1;
      let params = { ...this.params, done: done }
      if (params.selectTime && params.selectTime.length) {
        params.start = params.selectTime[0]
        params.end = params.selectTime[1]
        params.selectTime = undefined
      } else {
        params.start = undefined
        params.end = undefined
      }
      await this.$api.apply.exportOrderDetail(params)
      this.$message.success('请求成功，请在下载中心查看进度')
    },
    async getData() {
      try {
        this.loading = true
        let params = { ...this.params }
        if (params.selectTime && params.selectTime.length) {
          params.start = params.selectTime[0]
          params.end = params.selectTime[1]
          params.selectTime = undefined
        } else {
          params.start = undefined
          params.end = undefined
        }
        const { list, total } = await this.$api.apply.getAudit(params)
        this.tableData = (list || []).map(item => {
          const auditStatusStr = this.$constant.auditStatus2.find(_ => {
			  if (this.params.status == 1) {
				if (item.auditStatus == 1) {
				  return _.value == item.status
				}
				return _.value == item.auditStatus
			  }
			  return _.value == item.status
		  }
          )?.label
		  const flowableStatusStr = this.$constant.auditStatus2.find(_ => {
			  if (this.params.status == 1) {
				if (item.status == 1) {
				  return _.value == item.status
				}
				return _.value == item.status
			  }
			  return _.value == item.status
		  }
		  )?.label

          const applyTypeStr = this.$constant.applyType.find(
            _ => _.value == item.attach
          )?.label

          return { ...item, auditStatusStr, flowableStatusStr, applyTypeStr }
        })
        this.total = total
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    toDetail(row) {
      this.$router.push({
        path: '/myAudit/detail',
        query: {
          processCode: row.unique,
          processInstanceId: row.processInstanceId,
          type: row.attach,
          from: 'myAudit'
        }
      })
    }
  }
}
</script>

<style lang="less" scoped></style>
